import payload_plugin_dHWDzdkIDW from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/payload-plugin.js";
import revive_payload_client_GrshLHrR7V from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__nu722mdfxllrcjdzkh2yh4hwr4/node_modules/nuxt/dist/app/plugins/revive-payload.client.js";
import unhead_NkqUXft8fb from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__nu722mdfxllrcjdzkh2yh4hwr4/node_modules/nuxt/dist/head/runtime/plugins/unhead.js";
import router_8bo0CFpzS1 from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__nu722mdfxllrcjdzkh2yh4hwr4/node_modules/nuxt/dist/pages/runtime/plugins/router.js";
import payload_client_WTeSvLkfIY from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__nu722mdfxllrcjdzkh2yh4hwr4/node_modules/nuxt/dist/app/plugins/payload.client.js";
import navigation_repaint_client_3rcKMTGg9x from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__nu722mdfxllrcjdzkh2yh4hwr4/node_modules/nuxt/dist/app/plugins/navigation-repaint.client.js";
import check_outdated_build_client_7TuwwiqHxl from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__nu722mdfxllrcjdzkh2yh4hwr4/node_modules/nuxt/dist/app/plugins/check-outdated-build.client.js";
import chunk_reload_client_0XCjhkrjN0 from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__nu722mdfxllrcjdzkh2yh4hwr4/node_modules/nuxt/dist/app/plugins/chunk-reload.client.js";
import plugin_vue3_UtRw2ejqZa from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/@pinia+nuxt@0.9.0_magicast@0.3.5_pinia@2.3.1_typescript@5.7.3_vue@3.5.13_typescript@5.7.3___rollup@4.31.0/node_modules/@pinia/nuxt/dist/runtime/plugin.vue3.js";
import components_plugin_KR1HBZs4kY from "/home/ubuntu/beekk_nuxtjs/.nuxt/components.plugin.mjs";
import prefetch_client_19Z7FBqw5M from "/home/ubuntu/beekk_nuxtjs/node_modules/.pnpm/nuxt@3.15.2_@parcel+watcher@2.5.0_@types+node@20.17.16_db0@0.2.1_eslint@8.57.1_ioredis@5.4.2__nu722mdfxllrcjdzkh2yh4hwr4/node_modules/nuxt/dist/pages/runtime/plugins/prefetch.client.js";
import element_plus_teleports_plugin_h4Dmekbj62 from "/home/ubuntu/beekk_nuxtjs/.nuxt/element-plus-teleports.plugin.mjs";
import element_plus_injection_plugin_1RNPi6ogby from "/home/ubuntu/beekk_nuxtjs/.nuxt/element-plus-injection.plugin.mjs";
import bootstrap_client_Uyd73t1ylZ from "/home/ubuntu/beekk_nuxtjs/plugins/bootstrap.client.ts";
import element_plus_HUzmwi0JYR from "/home/ubuntu/beekk_nuxtjs/plugins/element-plus.ts";
import vue_json_viewer_koKP6p2V4D from "/home/ubuntu/beekk_nuxtjs/plugins/vue-json-viewer.ts";
import api_GFfDXud5Cr from "/home/ubuntu/beekk_nuxtjs/plugins/api.ts";
import dayjs_pGiXRjcsJO from "/home/ubuntu/beekk_nuxtjs/plugins/dayjs.ts";
import errorHandler_x1Y4n5NP4w from "/home/ubuntu/beekk_nuxtjs/plugins/errorHandler.ts";
import vue_awesome_paginate_rvI9ZlY63V from "/home/ubuntu/beekk_nuxtjs/plugins/vue-awesome-paginate.ts";
export default [
  payload_plugin_dHWDzdkIDW,
  revive_payload_client_GrshLHrR7V,
  unhead_NkqUXft8fb,
  router_8bo0CFpzS1,
  payload_client_WTeSvLkfIY,
  navigation_repaint_client_3rcKMTGg9x,
  check_outdated_build_client_7TuwwiqHxl,
  chunk_reload_client_0XCjhkrjN0,
  plugin_vue3_UtRw2ejqZa,
  components_plugin_KR1HBZs4kY,
  prefetch_client_19Z7FBqw5M,
  element_plus_teleports_plugin_h4Dmekbj62,
  element_plus_injection_plugin_1RNPi6ogby,
  bootstrap_client_Uyd73t1ylZ,
  element_plus_HUzmwi0JYR,
  vue_json_viewer_koKP6p2V4D,
  api_GFfDXud5Cr,
  dayjs_pGiXRjcsJO,
  errorHandler_x1Y4n5NP4w,
  vue_awesome_paginate_rvI9ZlY63V
]