
if (import.meta.hot) {
  import.meta.hot.accept((mod) => {
    const router = import.meta.hot.data.router
    const generateRoutes = import.meta.hot.data.generateRoutes
    if (!router || !generateRoutes) {
      import.meta.hot.invalidate('[nuxt] Cannot replace routes because there is no active router. Reloading.')
      return
    }
    router.clearRoutes()
    const routes = generateRoutes(mod.default || mod)
    function addRoutes (routes) {
      for (const route of routes) {
        router.addRoute(route)
      }
      router.replace(router.currentRoute.value.fullPath)
    }
    if (routes && 'then' in routes) {
      routes.then(addRoutes)
    } else {
      addRoutes(routes)
    }
  })
}

export function handleHotUpdate(_router, _generateRoutes) {
  if (import.meta.hot) {
    import.meta.hot.data ||= {}
    import.meta.hot.data.router = _router
    import.meta.hot.data.generateRoutes = _generateRoutes
  }
}
import { default as listsX2pc3lsPW2Meta } from "/home/ubuntu/beekk_nuxtjs/pages/bbs/lists.vue?macro=true";
import { default as recommend_listsHg4lapdQtTMeta } from "/home/ubuntu/beekk_nuxtjs/pages/bbs/recommend_lists.vue?macro=true";
import { default as indexwVdryfG1R4Meta } from "/home/ubuntu/beekk_nuxtjs/pages/index.vue?macro=true";
import { default as logine9i3bTWaWlMeta } from "/home/ubuntu/beekk_nuxtjs/pages/login.vue?macro=true";
import { default as dashboardT16BHu9r6CMeta } from "/home/ubuntu/beekk_nuxtjs/pages/main/dashboard.vue?macro=true";
import { default as listsB15RjisCBDMeta } from "/home/ubuntu/beekk_nuxtjs/pages/member/lists.vue?macro=true";
import { default as _91id_939OiNvjfE2KMeta } from "/home/ubuntu/beekk_nuxtjs/pages/product/details/[id].vue?macro=true";
import { default as registerKXbiRhFdBcMeta } from "/home/ubuntu/beekk_nuxtjs/pages/product/register.vue?macro=true";
import { default as temporary_register6x5VvxUKs2Meta } from "/home/ubuntu/beekk_nuxtjs/pages/product/temporary_register.vue?macro=true";
import { default as temporary18Muli20AtMeta } from "/home/ubuntu/beekk_nuxtjs/pages/product/temporary.vue?macro=true";
import { default as _91id_93N9jkwFtQDqMeta } from "/home/ubuntu/beekk_nuxtjs/pages/product/temporaryDetails/[id].vue?macro=true";
export default [
  {
    name: "bbs-lists",
    path: "/bbs/lists",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/bbs/lists.vue")
  },
  {
    name: "bbs-recommend_lists",
    path: "/bbs/recommend_lists",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/bbs/recommend_lists.vue")
  },
  {
    name: "index",
    path: "/",
    meta: indexwVdryfG1R4Meta || {},
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/index.vue")
  },
  {
    name: "login",
    path: "/login",
    meta: logine9i3bTWaWlMeta || {},
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/login.vue")
  },
  {
    name: "main-dashboard",
    path: "/main/dashboard",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/main/dashboard.vue")
  },
  {
    name: "member-lists",
    path: "/member/lists",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/member/lists.vue")
  },
  {
    name: "product-details-id",
    path: "/product/details/:id()",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/product/details/[id].vue")
  },
  {
    name: "product-register",
    path: "/product/register",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/product/register.vue")
  },
  {
    name: "product-temporary_register",
    path: "/product/temporary_register",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/product/temporary_register.vue")
  },
  {
    name: "product-temporary",
    path: "/product/temporary",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/product/temporary.vue")
  },
  {
    name: "product-temporaryDetails-id",
    path: "/product/temporaryDetails/:id()",
    component: () => import("/home/ubuntu/beekk_nuxtjs/pages/product/temporaryDetails/[id].vue")
  }
]