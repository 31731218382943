<template>
  <div class="wrapper">
    <AppSidebar :auth="auth" />
    <div class="main">
      <AppHeader />
      <slot />
      <AppFooter />
    </div>
  </div>
</template>

<script setup lang="ts">
import AppHeader from '@/components/AppHeader.vue';
import AppFooter from '@/components/AppFooter.vue';
import AppSidebar from '@/components/AppSidebar.vue';
import type { Auth } from '@/types/auth/auth';

const auth = ref<Auth | null>(null);
const router = useRouter();
const route = useRoute();
const authStore = useAuthStore();

const fetchAuth = async () => {
  try {
    const result = await authStore.authInfo();

    if (!result) {
      window.location.href = `/login?redirect=${route.fullPath}`;
    }

    auth.value = result;
  } catch (error) {
    console.error('An error occurred:', error);
    await router.push('/');
  }
};

onBeforeMount(async () => {
  await fetchAuth();
});
</script>
<style lang="scss">
main.content {
  margin-top: 1rem;
}
</style>
