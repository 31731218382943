<template>
  <main class="main h-100 w-100">
    <slot />
  </main>
</template>

<script setup lang="ts">
const authStore = useAuthStore();
const router = useRouter();

const fetchAuth = async () => {
  try {
    const result = await authStore.authInfo();

    if (result) {
      window.location.href = '/main/dashboard';
      // await router.push('/main/dashboard');
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
};

onBeforeMount(async () => {
  await fetchAuth();
});

// onMounted(async () => {
//   await fetchAuth();
// });
</script>
<style scoped lang="scss">
.main {
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100vh;
  width: 100vw;
}
</style>
