<script setup lang="ts">
const route = useRoute();
const router = useRouter();

const email = ref<string>('');
const password = ref<string>('');

const authStore = useAuthStore();

const signIn = async () => {
  try {
    const response = await authStore.login({
      email: email.value,
      password: password.value,
    });

    // 성공적으로 로그인한 후에 대시보드 페이지로 이동
    if (response) {
      let redirectTo = '/main/dashboard';

      if (route.query.redirect) {
        if (Array.isArray(route.query.redirect)) {
          redirectTo = route.query.redirect[0] as string; // 배열인 경우 첫 번째 요소 사용
        } else {
          redirectTo = route.query.redirect;
        }
      }

      window.location.href = redirectTo;

      // await router.push(redirectTo);
    } else {
      // 로그인 실패 시 처리
      console.error('Login failed:', response.message);
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
};
</script>

<template>
  <div class="container h-100">
    <div class="row h-100">
      <div class="col-sm-10 col-md-8 col-lg-6 mx-auto d-table h-100">
        <div class="d-table-cell align-middle">
          <div class="text-center mt-4">
            <h1 class="h2">Welcome back to BEEKK</h1>
            <p class="lead">Sign in to your account to continue</p>
          </div>

          <div class="card">
            <div class="card-body">
              <div class="m-sm-4">
                <div class="text-center">
                  <img
                    src="/img/common/black_log.png"
                    alt="Linda Miller"
                    class="img-fluid rounded-circle"
                  />
                </div>
                <form @submit.prevent="signIn">
                  <div class="mb-3">
                    <label>ID</label>
                    <CommonInput
                      id="input_id"
                      v-model="email"
                      type="text"
                      placeholder="ID"
                    />
                  </div>
                  <div class="mb-3">
                    <label>Password</label>
                    <CommonInput
                      id="input_password"
                      v-model="password"
                      type="password"
                      placeholder="Password"
                    />
                    <small>
                      <a href="pages-reset-password.html">Forgot password?</a>
                    </small>
                  </div>
                  <div>
                    <div class="form-check align-items-center">
                      <input
                        id="customControlInline"
                        type="checkbox"
                        class="form-check-input"
                        value="remember-me"
                        name="remember-me"
                        checked
                      />
                      <label
                        class="form-check-label text-small"
                        for="customControlInline"
                        >Remember me next time</label
                      >
                    </div>
                  </div>
                  <div class="text-center mt-3">
                    <button type="submit" class="btn btn-lg btn-primary">
                      Sign in
                    </button>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style scoped></style>
