<template>
  <nav class="navbar navbar-expand navbar-theme">
    <a class="sidebar-toggle d-flex me-2" @click="">
      <i class="hamburger align-self-center"></i>
    </a>

    <div class="navbar-collapse collapse">
      <ul class="navbar-nav ms-auto">
        <li class="nav-item dropdown active">
          <a
            id="messagesDropdown"
            class="nav-link dropdown-toggle position-relative"
            href="#"
            data-bs-toggle="dropdown"
          >
            <i class="align-middle fas fa-envelope-open"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
            aria-labelledby="messagesDropdown"
          >
            <div class="dropdown-menu-header">
              <div class="position-relative">4 New Messages</div>
            </div>
            <div class="list-group">
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <img
                      src="/img/avatars/avatar-5.jpg"
                      class="avatar img-fluid rounded-circle"
                      alt="Michelle Bilodeau"
                    />
                  </div>
                  <div class="col-10 ps-2">
                    <div class="text-dark">Michelle Bilodeau</div>
                    <div class="text-muted small mt-1">
                      Nam pretium turpis et arcu. Duis arcu tortor.
                    </div>
                    <div class="text-muted small mt-1">5m ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <img
                      src="/img/avatars/avatar-3.jpg"
                      class="avatar img-fluid rounded-circle"
                      alt="Kathie Burton"
                    />
                  </div>
                  <div class="col-10 ps-2">
                    <div class="text-dark">Kathie Burton</div>
                    <div class="text-muted small mt-1">
                      Pellentesque auctor neque nec urna.
                    </div>
                    <div class="text-muted small mt-1">30m ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <img
                      src="/img/avatars/avatar-2.jpg"
                      class="avatar img-fluid rounded-circle"
                      alt="Alexander Groves"
                    />
                  </div>
                  <div class="col-10 ps-2">
                    <div class="text-dark">Alexander Groves</div>
                    <div class="text-muted small mt-1">
                      Curabitur ligula sapien euismod vitae.
                    </div>
                    <div class="text-muted small mt-1">2h ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <img
                      src="/img/avatars/avatar-4.jpg"
                      class="avatar img-fluid rounded-circle"
                      alt="Daisy Seger"
                    />
                  </div>
                  <div class="col-10 ps-2">
                    <div class="text-dark">Daisy Seger</div>
                    <div class="text-muted small mt-1">
                      Aenean tellus metus, bibendum sed, posuere ac, mattis non.
                    </div>
                    <div class="text-muted small mt-1">5h ago</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="dropdown-menu-footer">
              <a href="#" class="text-muted">Show all messages</a>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown ms-lg-2">
          <a
            id="alertsDropdown"
            class="nav-link dropdown-toggle position-relative"
            href="#"
            data-bs-toggle="dropdown"
          >
            <i class="align-middle fas fa-bell"></i>
            <span class="indicator"></span>
          </a>
          <div
            class="dropdown-menu dropdown-menu-lg dropdown-menu-end py-0"
            aria-labelledby="alertsDropdown"
          >
            <div class="dropdown-menu-header">4 New Notifications</div>
            <div class="list-group">
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <i class="ms-1 text-danger fas fa-fw fa-bell"></i>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">Update completed</div>
                    <div class="text-muted small mt-1">
                      Restart server 12 to complete the update.
                    </div>
                    <div class="text-muted small mt-1">2h ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <i class="ms-1 text-warning fas fa-fw fa-envelope-open"></i>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">Lorem ipsum</div>
                    <div class="text-muted small mt-1">
                      Aliquam ex eros, imperdiet vulputate hendrerit et.
                    </div>
                    <div class="text-muted small mt-1">6h ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <i class="ms-1 text-primary fas fa-fw fa-building"></i>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">Login from 192.186.1.1</div>
                    <div class="text-muted small mt-1">8h ago</div>
                  </div>
                </div>
              </a>
              <a href="#" class="list-group-item">
                <div class="row g-0 align-items-center">
                  <div class="col-2">
                    <i class="ms-1 text-success fas fa-fw fa-bell-slash"></i>
                  </div>
                  <div class="col-10">
                    <div class="text-dark">New connection</div>
                    <div class="text-muted small mt-1">
                      Anna accepted your request.
                    </div>
                    <div class="text-muted small mt-1">12h ago</div>
                  </div>
                </div>
              </a>
            </div>
            <div class="dropdown-menu-footer">
              <a href="#" class="text-muted">Show all notifications</a>
            </div>
          </div>
        </li>
        <li class="nav-item dropdown ms-lg-2">
          <a
            id="userDropdown"
            class="nav-link dropdown-toggle position-relative"
            href="#"
            data-bs-toggle="dropdown"
          >
            <i class="align-middle fas fa-cog"></i>
          </a>
          <div
            class="dropdown-menu dropdown-menu-end"
            aria-labelledby="userDropdown"
          >
            <a class="dropdown-item" href="#"
              ><i class="align-middle me-1 fas fa-fw fa-user"></i> View
              Profile</a
            >
            <a class="dropdown-item" href="#"
              ><i class="align-middle me-1 fas fa-fw fa-comments"></i>
              Contacts</a
            >
            <a class="dropdown-item" href="#"
              ><i class="align-middle me-1 fas fa-fw fa-chart-pie"></i>
              Analytics</a
            >
            <a class="dropdown-item" href="#"
              ><i class="align-middle me-1 fas fa-fw fa-cogs"></i> Settings</a
            >
            <div class="dropdown-divider"></div>
            <a class="dropdown-item" href="#" @click.prevent="onClickLogout"
              ><i
                class="align-middle me-1 fas fa-fw fa-arrow-alt-circle-right"
              ></i>
              Sign out</a
            >
          </div>
        </li>
      </ul>
    </div>
  </nav>
</template>

<script setup lang="ts">
const authStore = useAuthStore();

const onClickLogout = async () => {
  try {
    const response = await authStore.logout();

    if (response) {
      location.href = '/';
    }
  } catch (error) {
    console.error('An error occurred:', error);
  }
};

onMounted(() => {
  const sidebar = document.getElementById('sidebar');
  const sidebarToggle = document.querySelector('.sidebar-toggle');
  sidebarToggle?.addEventListener('click', () => {
    sidebar?.classList.toggle('toggled');
  });
});
</script>
