import api from '@/utils/api';
import { queryString } from '@/utils/common';

export function postLogin(data) {
  const request = {
    method: 'POST',
    body: {
      userId: data.email,
      userPw: data.password,
    },
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api('/v1/auth/login', request);
}

export function postLogout() {
  const request = {
    method: 'POST',
    headers: {
      'Content-Type': 'application/json',
    },
  };

  return api('/v1/auth/logout', request);
}

export function getAuth() {
  const reuqest = {
    method: 'GET',
    headers: {
      'Content-Type': 'application/json',
    },
  };
  return api('/v1/auth/auth', reuqest);
}
