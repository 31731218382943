import { defineStore } from 'pinia';
import type { Auth } from '@/types/auth/auth';
import {
  getAuth,
  postLogin,
  postLogout,
} from '@/composables/apiService/authService';

interface AuthState {
  token: string;
  user: Auth | null;
}

// Pinia 스토어 정의
export const useAuthStore = defineStore('auth', {
  // 상태 정의
  state: (): AuthState => ({
    token: '',
    user: null,
  }),

  // Getters 정의
  getters: {
    isAuthenticated: (state): boolean => !!state.token,
    getAdministrator(state): Auth | null {
      return state.user?.user_level === '1' ? state.user : null;
    },
  },

  // Actions 정의
  actions: {
    async login(request: { email: string; password: string }) {
      try {
        const { token } = await postLogin(request);

        this.token = token;

        //토큰 저장 (쿠키)
        document.cookie = `accessToken=${token}`;

        return token;
      } catch (error) {
        return false;
      }
    },
    loadTokenFromCookie() {
      const token = document.cookie
        .split('; ')
        .find(row => row.startsWith('accessToken='))
        ?.split('=')[1];
      if (token) {
        this.token = token;
      }
    },
    async logout() {
      try {
        const data = await postLogout();

        this.token = '';
        this.user = null;

        // 토큰 삭제 (쿠키)
        document.cookie = `accessToken =; expires=Thu, 01 Jan 1970 00:00:00 UTC; path=/;`;

        return data;
      } catch (error) {
        return false;
      }
    },
    async authInfo() {
      try {
        const data = await getAuth();

        this.user = data;

        return data;
      } catch (error) {
        return false;
      }
    },
  },
});
