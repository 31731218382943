// utils/api.ts
import { $fetch } from 'ofetch';

const env = process.env.NODE_ENV;

console.log('env:', env);

const apiBaseURL =
  env !== 'development'
    ? 'https://adm.dev-beekk.kr/api'
    : 'http://localhost:8000/api';
// const apiBaseURL = 'http://localhost:8000/api';

const api = $fetch.create({
  baseURL: apiBaseURL,
  headers: {
    'Content-Type': 'application/json',
  },
  onRequest({ request, options }) {
    // 요청 전에 수행할 작업 추가 (예: 인증 토큰 추가)
    // console.log('Request:', request);

    const headers = new Headers(options.headers);
    const token = useCookie('accessToken');
    if (token) {
      headers.set('Authorization', `Bearer ${token}`);
    }
    options.headers = headers;
    options.credentials = 'include';
  },
  onResponse({ request, response, options }) {
    // 응답 후에 수행할 작업 추가 (예: 에러 처리)
    // console.log('Response:', response);
    if (!response.ok) {
      // 예: 에러 메시지를 사용자에게 표시
      console.error(`Error: ${response.statusText}`);
    }
  },
  onRequestError({ request, options, error }) {
    // 요청 오류 처리
    console.error('Request Error:', error);
  },
  onResponseError({ request, response, options }) {
    // 응답 오류 처리
    console.error('Response Error:', response);
  },
});

export default api;
